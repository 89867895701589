<template>
	<div>
		<div class="row form-inline justify-content-between">
			<DevolucaoPesquisaPorNota />
			<div class="col-5 pb-65">
				<button
					id="lmxta-identificacao-btn-fornecedor"
					type="button"
					data-toggle="modal"
					data-target="#ModalPesquisaCliente"
					@click="abrirSelecaoFornecedor()"
					class="btn btn-secondary text-center identificacao-cliente-button w-100 form-control-sm"
					data-backdrop="static"
					data-keyboard="false"
					:disabled="notasAssociadas.length > 0"
					v-tooltip.bottom="apresentarTooltip('Escolha o fornecedor para realizar a devolução.')"
				>
					<div class="truncate" v-if="!identificacao.Fornecedor">Informe o fornecedor</div>
					<div class="truncate">{{ nomeFornecedor }}</div>
				</button>
			</div>
		</div>

		<FornecedorSelecao
			v-if="UI.SelecionandoFornecedor"
			@fechar="UI.SelecionandoFornecedor = false"
			@selecionar="selecionarFornecedor"
		/>

		<div v-if="possuiItensParaDevolucao" class="mt-auto">
			<button
				type="button"
				class="btn btn-default btn-sm mt-3"
				:disabled="totalizador.Selecionados <= 0"
				@click="removerItensSelecionados"
				v-tooltip.bottom="apresentarTooltip('Remover os itens selecionados da devolução.')"
			>
				<i class="fas fa-trash-alt"></i> Excluir
			</button>
			<button
				v-if="itensSemVinculo.length > 0"
				v-tooltip.bottom="apresentarTooltip('Será associada a nota fiscal de entrada mais recente de cada item.')"
				type="button"
				class="btn btn-success btn-sm mt-3 ml-2"
				@click="iniciarAssociacaoAutomatica"
			>
				<span> <i class="fas fa-magic"></i> Associar Automaticamente </span>
			</button>
			<div class="pt-3 table-responsive height-nota">
				<table v-if="itensSemVinculo.length > 0" class="table table-hover table-sm table-striped mb-0">
					<thead>
						<tr style="cursor: pointer" class="tr-group-by" @click="expandirAgrupamentoSemVinculo">
							<td colspan="10">
								<span class="fz-90">
									<span v-show="UI.Grid.AgrupamentoSemVinculo.Expandido">
										<i class="fas fa-caret-down"></i>
									</span>
									<span v-show="!UI.Grid.AgrupamentoSemVinculo.Expandido">
										<i class="fas fa-caret-right"></i>
									</span>
									<strong> Pendente</strong> -
									<em>
										Você deve associar uma nota de origem
										<strong class="text-primary">Manualmente</strong>
										ou <strong class="text-primary">Automaticamente</strong>
									</em>
								</span>
							</td>
						</tr>

						<template v-if="UI.Grid.AgrupamentoSemVinculo.Expandido">
							<tr class="overflow-hidden">
								<th class="text-center" width="5%">
									<input
										type="checkbox"
										:checked="todosItensSemVinculoSelecionados"
										@change="selecionarTodosItensSemVinculo($event.target.checked)"
									/>
								</th>
								<th class="col-produto-pendente">Produto</th>
								<th class="text-center input-quantidade">Quantidade</th>
							</tr>
						</template>
					</thead>
					<tbody>
						<template v-if="UI.Grid.AgrupamentoSemVinculo.Expandido">
							<tr v-for="(item, indice) in itensSemVinculo" v-bind:key="indice" :set="(prod = obterProduto(item))">
								<td class="text-center">
									<input
										type="checkbox"
										:checked="item.Selecionado"
										@change="selecionarItemSemVinculo($event.target.checked, item)"
									/>
								</td>
								<td>
									{{ prod.Descricao }}
									<span class="small">(Ref:{{ prod.Referencia }} | Cód:{{ item.CodigoProduto }})</span>.
								</td>
								<td
									><input-quantity
										v-model="item.UnidadeSelecionada.Quantidade"
										:unidade="item.UnidadeSelecionada.Descricao"
										:precisao="item.UnidadeSelecionada.CasasDecimais"
								/></td>
							</tr>
						</template>
					</tbody>
				</table>

				<table
					v-for="(nota, index) in notasAssociadas"
					:key="index"
					class="table table-hover table-sm table-striped mb-0"
				>
					<thead>
						<tr style="cursor: pointer" class="tr-group-by" @click="selecionarAgrupamentoNotasAssociadas(index)">
							<td colspan="11">
								<strong id="span-numero-serie-nota" class="fz-90">
									<span v-show="UI.Grid.AgrupamentoNotasAssociadas[index].Expandir">
										<i class="fas fa-caret-down"></i>
									</span>
									<span id="span-expandir" v-show="!UI.Grid.AgrupamentoNotasAssociadas[index].Expandir">
										<i class="fas fa-caret-right"></i>
									</span>
									Nota {{ nota.Numero }}/{{ nota.Serie }}.
								</strong>
							</td>
						</tr>
						<template v-if="UI.Grid.AgrupamentoNotasAssociadas[index].Expandir">
							<tr class="overflow-hidden">
								<th class="text-center" width="5%">
									<input
										id="input-marcar-desmarcar-todos-produtos"
										type="checkbox"
										v-model="UI.Grid.AgrupamentoNotasAssociadas[index].TodosSelecionados"
										@change="selecionarTodosNotasAssociadas(index, $event.target.checked)"
									/>
								</th>
								<th class="col-produto-associado"></th>
								<th
									v-if="notaPermiteAlterarUnidade(nota)"
									@click="replicarUnidade(nota, index)"
									v-tooltip.top="
										apresentarTooltip('Replicar a unidade da primeira linha para todos os produtos da nota.')
									"
									style="cursor: pointer"
									class="text-center"
									>Unidade
									<i class="fas fa-clone"></i>
								</th>
								<th class="text-center input-quantidade">Quantidade</th>
								<th class="text-right" width="10%">Preço unitário</th>
								<th class="text-right col-valor-total">Valor total</th>
							</tr>
						</template>
					</thead>
					<tbody>
						<template v-if="UI.Grid.AgrupamentoNotasAssociadas[index].Expandir">
							<tr v-for="(item, indice) in nota.Itens" v-bind:key="indice" :set="(prod = obterProduto(item))">
								<td class="text-center">
									<input
										id="input-marcar-desmarcar-produto"
										type="checkbox"
										:checked="item.Selecionado"
										@change="selecionarItemNotaAssociada(item, index, $event.target.checked)"
									/>
								</td>
								<td id="td-descricao-produto">
									{{ prod.Descricao }}
									<span id="span-referencia-produto" class="small">
										(Ref:{{ prod.Referencia }} | Cód:{{ item.CodigoProduto }}
										<span v-if="item.Serial"> | Serial: {{ item.Serial }}</span>
										<span v-if="controlaLote && item.Lote"> | Lote: {{ item.Lote }}</span
										>)
									</span>
								</td>
								<template v-if="notaPermiteAlterarUnidade(nota)">
									<td v-if="itemPermiteAlterarUnidade(item)">
										<select
											v-model="item.UnidadeSelecionada"
											@change="
												alterarUnidadeItemNotaAssociada(
													index,
													indice,
													$event.target.selectedOptions[0]._value,
												)
											"
											:disabled="desabilitaInputUnidade(item) || desabilitarInputUnidadeCompra(item)"
											v-tooltip.bottom="
												(desabilitaInputUnidade(item) &&
													apresentarTooltip('Produto não possui unidade de compra cadastrada.')) ||
												(desabilitarInputUnidadeCompra(item) &&
													apresentarTooltip(
														`A devolução em ${item.UnidadeCompra?.Descricao} só é possível quando a quantidade disponível for igual ou superior a ${item.UnidadeCompra?.Descricao} completa.`,
													))
											"
											class="form-control form-control-sm"
											:id="'unidadeSelect_' + item.CodigoProduto"
										>
											<option :value="item.UnidadeCompra" :key="item.UnidadeCompra?.Id"
												>Unidade de compra - {{ item.UnidadeCompra?.Descricao }}</option
											>
											<option selected :value="item.UnidadeVenda" :key="item.UnidadeVenda.Id"
												>Unidade de venda - {{ item.UnidadeVenda.Descricao }}</option
											>
										</select>
									</td>
									<td v-else></td>
								</template>
								<td id="td-quantidade-produto">
									<input-quantity
										v-model="item.Quantidade"
										@input="verificarQuantidadeProduto($event, item)"
										:unidade="item.UnidadeSelecionada.Descricao"
										:precisao="item.UnidadeSelecionada.CasasDecimais"
										:faixa-valor="{ min: 0 }"
									></input-quantity>
								</td>
								<td id="td-preco-unitario-produto" class="text-right">{{ item.PrecoUnitario | money }}</td>
								<td id="td-valor-total-produto" class="text-right">{{
									(item.PrecoUnitario * item.Quantidade) | money
								}}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<div v-else class="card-panel pt-1 px-2 pb-0 d-flex flex-column justify-content-between mensagem-nota-centralizada">
			<div class="d-flex flex-column justify-content-center h-100">
				<div class="text-center w-100">
					<p class="text-uppercase fz-95">Nenhuma nota selecionada</p>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-between">
			<button id="button-cancelar" type="button" class="btn btn-default col-3" @click="cancelar"
				><i class="fas fa-trash-alt"></i> Cancelar</button
			>
			<div>
				<span id="span-resumo-devolucao" class="text-uppercase small" style="cursor: default"
					>{{ totalizador.Inseridos }} itens inseridos | {{ totalizador.QuantidadeItens }} unidades</span
				>
			</div>
			<button
				id="button-devolver"
				type="button"
				class="btn btn-primary col-3"
				@click="devolver"
				:disabled="!possuiNotasAssociadas"
				>Devolver</button
			>
		</div>
	</div>
</template>

<script>
	import loading from '@/common/loading';
	import swal from '@/common/alerts';
	import { ID_UNIDADE } from '@/core/enums/unidade-compra-venda.enum';
	import InputQuantity from '@/components/shared/input/InputQuantity.vue';
	import FornecedorSelecao from '@/components/shared/crm/FornecedorSelecao.vue';
	import DevolucaoPesquisaPorNota from './DevolucaoPesquisaPorNota.vue';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';
	import { mapState } from 'vuex';

	Vue.use(VTooltip);

	export default {
		name: 'DevolucaoPorNotas',

		components: {
			InputQuantity,
			FornecedorSelecao,
			DevolucaoPesquisaPorNota,
		},

		data() {
			return {
				CodigoBarrasItem: '',
				QuantidadeItem: 1,
				UI: {
					SelecionandoFornecedor: false,
					UploadArquivoProdutos: false,
					Grid: {
						AgrupamentoSemVinculo: {
							Expandido: true,
						},
						AgrupamentoNotasAssociadas: [],
					},
					AssociarErro: '',
					DevolucaoPorNota: {
						PesquisaPorNumeroSerie: true,
						Pesquisa: {
							Chave: '',
							Numero: '',
							Serie: '',
						},
					},
				},
			};
		},

		watch: {
			notasAssociadas: {
				handler(val) {
					if (val.length == 0) {
						this.UI.Grid.AgrupamentoNotasAssociadas = [];
						return;
					}

					if (this.UI.Grid.AgrupamentoNotasAssociadas.length == val.length) return;

					this.UI.Grid.AgrupamentoNotasAssociadas = [];
					this.UI.Grid.AgrupamentoNotasAssociadas = [...Array(val.length)].map((u) => ({
						Expandir: true,
						TodosSelecionados: false,
					}));
					return;
				},
				immediate: true,
			},
		},

		computed: {
			...mapState({
				identificacao: (state) => state.devolucao.Identificacao,
				itensSemVinculo: (state) => state.devolucao.ItensSemVinculo,
				notasAssociadas: (state) => state.devolucao.NotasAssociadas,
				pesquisaProdutoAceitaAlfanumerico: (state) =>
					state.devolucao.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico,
				notaAssociarManualmente: (state) => state.devolucao.Associacao,
				produtos: (state) => state.devolucao.Produtos,
				controlaLote: (state) => state.devolucao.Configuracoes.ControlaLote,
			}),

			nomeFornecedor() {
				if (this.identificacao.Fornecedor) {
					return this.identificacao.Fornecedor.Nome;
				}

				return '';
			},

			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},

			totalizador() {
				let totalizador = {
					Inseridos: 0,
					QuantidadeItens: 0,
					Selecionados: 0,
				};

				this.itensSemVinculo.forEach((item) => {
					totalizador.Inseridos++;
					totalizador.QuantidadeItens += item.Quantidade;

					if (item.Selecionado) totalizador.Selecionados++;
				});

				this.notasAssociadas.forEach((nota) => {
					nota.Itens.forEach((item) => {
						totalizador.Inseridos++;
						totalizador.QuantidadeItens += item.Quantidade;

						if (item.Selecionado) totalizador.Selecionados++;
					});
				});

				return totalizador;
			},

			possuiNotasAssociadas() {
				return this.notasAssociadas && this.notasAssociadas.length > 0;
			},

			possuiItensParaDevolucao() {
				return this.itensSemVinculo.length > 0 || this.notasAssociadas.length > 0;
			},

			todosItensSemVinculoSelecionados() {
				return this.itensSemVinculo.every((item) => item.Selecionado == true);
			},

			associarManualmenteDisponivel() {
				return this.totalizador.Selecionados > 0;
			},
		},

		methods: {
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},

			selecionarItemSemVinculo(selecionado, item) {
				var indiceProdutoExistente;
				var itemExistente = this.itensSemVinculo.find(function (itemSemVinculo, indice) {
					indiceProdutoExistente = indice;
					return (
						itemSemVinculo.CodigoProduto == item.CodigoProduto ||
						(typeof itemSemVinculo.CodigoBarras !== 'undefined' && itemSemVinculo.CodigoBarras == item.CodigoBarras)
					);
				});

				itemExistente.Selecionado = selecionado;

				this.$store.dispatch('devolucao/atualizarItemSemVinculo', { indiceProdutoExistente, itemExistente });
			},

			selecionarItemNotaAssociada(item, indiceNota, selecionado) {
				var indiceItemExistente;
				var itemExistente = this.notasAssociadas[indiceNota].Itens.find(function (itemNotaAssociada, indice) {
					indiceItemExistente = indice;
					if (item.Serial) {
						return itemNotaAssociada.Serial == item.Serial;
					} else return itemNotaAssociada.CodigoProduto == item.CodigoProduto || (typeof itemNotaAssociada.CodigoBarras !== 'undefined' && itemNotaAssociada.CodigoBarras == item.CodigoBarras);
				});

				itemExistente.Selecionado = selecionado;
				let todosSelecionados = this.notasAssociadas[indiceNota].Itens.every((item) => item.Selecionado == true);
				this.UI.Grid.AgrupamentoNotasAssociadas[indiceNota].TodosSelecionados = todosSelecionados;

				this.$store.dispatch('devolucao/atualizarItemNotaAssociada', { indiceNota, indiceItemExistente, itemExistente });
			},

			alterarUnidadeItemNotaAssociada(indiceNota, indiceItem, unidade) {
				this.$store.commit('devolucao/alterarUnidadeItemNotaAssociada', { indiceNota, indiceItem, unidade });
			},

			selecionarTodosNotasAssociadas(index, selecionado) {
				let itens = this.notasAssociadas[index].Itens.map((item) => {
					item.Selecionado = selecionado;
					return item;
				});

				this.$store.dispatch('devolucao/selecionarTodosItensAssociados', { index, itens });
			},

			selecionarTodosItensSemVinculo(selecionado) {
				let itens = this.itensSemVinculo.map((item) => {
					item.Selecionado = selecionado;
					return item;
				});

				this.$store.dispatch('devolucao/atualizarTodosItensSemVinculo', itens);
			},

			removerItensSelecionados() {
				this.removerItensSemVinculo();
				this.removerItensDeNotasAssociadas();
			},

			removerItensSemVinculo() {
				for (let i = this.itensSemVinculo.length - 1; i >= 0; i--) {
					if (this.itensSemVinculo[i].Selecionado) {
						let indiceRemocao = this.itensSemVinculo.indexOf(this.itensSemVinculo[i]);
						this.$store.dispatch('devolucao/removerItemSemVinculo', indiceRemocao);
					}
				}
			},

			removerItensDeNotasAssociadas() {
				if (!this.notasAssociadas) return;

				for (let index = this.notasAssociadas.length - 1; index >= 0; index--) {
					const notaAssociada = this.notasAssociadas[index];

					for (let i = notaAssociada.Itens.length - 1; i >= 0; i--) {
						if (notaAssociada.Itens[i].Selecionado) {
							let indiceRemocao = notaAssociada.Itens.indexOf(notaAssociada.Itens[i]);
							let idNotaAssociada = notaAssociada.Id;
							this.$store.dispatch('devolucao/removerItemNotaAssociada', { indiceRemocao, idNotaAssociada });
						}
					}

					if (notaAssociada.Itens.length <= 0) this.$store.dispatch('devolucao/removerNotaAssociada', index);
				}
			},

			abrirSelecaoFornecedor() {
				this.UI.SelecionandoFornecedor = true;
			},

			selecionarFornecedor(fornecedor) {
				this.$store.dispatch('devolucao/atualizarFornecedor', fornecedor);
			},

			expandirAgrupamentoSemVinculo() {
				this.UI.Grid.AgrupamentoSemVinculo.Expandido = !this.UI.Grid.AgrupamentoSemVinculo.Expandido;
			},

			selecionarAgrupamentoNotasAssociadas(index) {
				this.UI.Grid.AgrupamentoNotasAssociadas[index].Expandir =
					!this.UI.Grid.AgrupamentoNotasAssociadas[index].Expandir;
			},

			cancelar() {
				swal.exibirPergunta('', 'Deseja realmente cancelar esta devolução?', async (response) => {
					if (response) {
						this.$store.dispatch('devolucao/iniciarNovaDevolucao');
						this.$router.push({ name: 'DevolucaoIdentificacao' });
					}
				});
			},

			iniciarAssociacaoAutomatica() {
				if (!this.validarFornecedor()) {
					return;
				}

				loading.exibir();

				this.$store
					.dispatch('devolucao/associarAutomaticamente')
					.then((response) => {
						if (!response.data) {
							loading.ocultar();
							swal.exibirMensagemAlerta(
								'Nenhuma nota de entrada encontrada para realizar a associação de itens para devolução.<br/><br/>Lembre-se: Apenas os produtos cuja a nota de entrada foi lançada pelas <i>rotinas de entrada via XML</i> poderão ser devolvidas por esta solução.',
							);
							return;
						}

						this.$store.dispatch('devolucao/removerTodasNotasAssociadas');
						this.atualizarNotasReferenciadas(response.data.DevolucoesReferenciadas);
						this.atualizarItensNaoAssociados(response.data.ItensDesassociados);

						loading.ocultar();
					})
					.catch((error) => {
						loading.ocultar();
						swal.exibirMensagemErro('Ocorreu um problema');
						return;
					});
			},

			atualizarNotasReferenciadas(devolucoesReferenciadas) {
				devolucoesReferenciadas.forEach((devolucaoReferenciada) => {
					this.$store.dispatch('devolucao/inserirNotaAssociada', devolucaoReferenciada);
				});
			},

			atualizarItensNaoAssociados(itensDesassociados) {
				this.$store.dispatch('devolucao/atualizarTodosItensSemVinculo', itensDesassociados);

				if (itensDesassociados.length > 0) {
					swal.exibirMensagemAlerta(
						'Não foi possível realizar a associação de um ou mais itens. Verifique a lista de itens pendentes.',
					);
				}
			},

			atualizarItensNaoAssociadosSelecionados(itensDesassociados) {
				this.removerItensSelecionados();
				this.$store.dispatch('devolucao/atualizarMultiplosItensSemVinculo', itensDesassociados);

				if (itensDesassociados.length > 0) {
					swal.exibirMensagemAlerta(
						'Não foi possível realizar a associação de um ou mais itens. Verifique a lista de itens pendentes.',
					);
				}
			},

			associarManualmente() {
				if (!this.validarFornecedor()) {
					return;
				}

				loading.exibir();

				let itensDesassociados = this.listarItensParaAssociacaoManual();
				let nota = this.notaAssociarManualmente;

				this.$store
					.dispatch('devolucao/associarManualmente', { nota, itensDesassociados })
					.then((response) => {
						if (!response.data) {
							loading.ocultar();
							swal.exibirMensagemAlerta('Ocorreu um problema ao realizar a associação.');
							return;
						}

						if (response.data.Notificacao) {
							this.UI.AssociarErro = response.data.Notificacao;
							loading.ocultar();
							return;
						}

						this.$store.dispatch('devolucao/removerTodasNotasAssociadas');
						this.atualizarNotasReferenciadas(response.data.DevolucoesReferenciadas);
						this.atualizarItensNaoAssociadosSelecionados(response.data.ItensDesassociados);

						this.fecharAssociacaoManual();
						loading.ocultar();
					})
					.catch((error) => {
						loading.ocultar();
						swal.exibirMensagemErro('Ocorreu um problema');
						return;
					});
			},

			listarItensParaAssociacaoManual() {
				var itensSelecionadosNotas = this.listarItensSelecionadosNotasReferenciadas();
				let itensSelecionados = this.itensSemVinculo
					.filter((item) => item.Selecionado)
					.map(({ CodigoProduto, Quantidade }) => ({ CodigoProduto, Quantidade }));

				if (itensSelecionadosNotas && itensSelecionadosNotas.length > 0) {
					if (itensSelecionados.length <= 0) {
						itensSelecionados = itensSelecionadosNotas;
					} else {
						itensSelecionadosNotas.forEach((item) => {
							itensSelecionados.push({ CodigoProduto: item.CodigoProduto, Quantidade: item.Quantidade });
						});
					}
				}

				itensSelecionadosNotas = itensSelecionados.map(({ CodigoProduto, Quantidade }) => ({
					CodigoProduto,
					Quantidade,
				}));

				let itensParaAssociacao = [];

				itensSelecionadosNotas.forEach((item) => {
					var itemDesassociado = itensParaAssociacao.find((i) => i.CodigoProduto == item.CodigoProduto);

					if (itemDesassociado) itemDesassociado.Quantidade += item.Quantidade;
					else itensParaAssociacao.push(item);
				});

				return itensParaAssociacao;
			},

			listarItensSelecionadosNotasReferenciadas() {
				var itensParaAssociacaoManual = [];

				this.notasAssociadas.forEach((nota) => {
					nota.Itens.forEach((item) => {
						if (item.Selecionado) {
							itensParaAssociacaoManual.push(item);
						}
					});
				});

				this.removerItensDeNotasAssociadas();

				return itensParaAssociacaoManual;
			},

			devolver() {
				if (!this.notasAssociadas || this.notasAssociadas.length <= 0) {
					swal.exibirMensagemAlerta('É necessário inserir ao menos uma nota de origem para realizar a devolução.');
					return;
				}

				if (!this.validarFornecedor()) {
					return;
				}

				let notasQuantidadeLimite = this.notasAssociadas
					.map((nota) => {
						let itensQuantidadeLimite = nota.Itens.filter(
							(item) =>
								(item.Selecionado === true || nota.Itens.every((i) => !i.Selecionado)) &&
								item.Quantidade > item.QuantidadeLimite,
						);
						return { documento: nota.Numero, serie: nota.Serie, itens: itensQuantidadeLimite };
					})
					.filter((notas) => {
						return notas.itens.length > 0;
					});

				if (notasQuantidadeLimite.length > 0) {
					let mensagem = '';
					notasQuantidadeLimite.forEach(
						(nota) =>
							(mensagem +=
								nota.itens.length > 1
									? `<br> Os produtos ${nota.itens.flatMap((item) => item.CodigoProduto).join(',')}
						 da nota ${nota.documento}/${nota.serie} estão com a quantidade selecionada maior que a disponível.`
									: `<br> O produto ${nota.itens.flatMap((item) => item.CodigoProduto).join(',')}
						 da nota ${nota.documento}/${nota.serie} está com a quantidade selecionada maior que a disponível.`),
					);
					swal.exibirMensagem(mensagem, 3, null, null, null, { width: '60em' });
					return;
				}

				if (this.itensSemVinculo.length > 0) {
					swal.exibirMensagemAlerta(
						'Ainda existem itens não associados a uma de entrada, valide o agrupamento "Pendente".',
					);
					return;
				}

				let notasSelecionadas = this.notasAssociadas.map((nota) => {
					return { ...nota, Itens: nota.Itens.filter((item) => item.Selecionado === true) };
				});

				let notasSelecionadosParaConferencia = [];
				this.notasAssociadas.forEach((nota) => {
					const notaSelecionada = notasSelecionadas.find((n) => n.Id == nota.Id && n.Itens.length > 0);
					if (notaSelecionada) {
						notasSelecionadosParaConferencia.push({
							...nota,
							Itens: nota.Itens.filter((item) => notaSelecionada.Itens.find((i) => i.Transacao === item.Transacao)),
						});
					}
				});

				this.$store.dispatch('devolucao/atualizarNotasAssociadasComItemsSelecionados', notasSelecionadosParaConferencia);
				this.$router.push('conferencia');
			},

			validarFornecedor() {
				if (!this.identificacao.Fornecedor) {
					this.abrirSelecaoFornecedor();
					return false;
				}

				return true;
			},

			obterProduto(itemNota) {
				var produto = this.produtos[itemNota.CodigoProduto];

				return produto;
			},

			notaPermiteAlterarUnidade(nota) {
				return nota.Itens.some((item) => this.itemPermiteAlterarUnidade(item));
			},

			itemPermiteAlterarUnidade(item) {
				return item.FatorConversao > 1 && !item.Serial;
			},

			desabilitaInputUnidade(item) {
				return !item.UnidadeCompra;
			},

			desabilitarInputUnidadeCompra(item) {
				return item.UnidadeCompra?.QuantidadeLimite <= 0;
			},

			replicarUnidade(nota, indiceNota) {
				const primeiroItem = nota.Itens[0];
				const ehUnidadeCompra = primeiroItem.UnidadeSelecionada.Id == ID_UNIDADE.Compra;

				nota.Itens.forEach((item, indiceItem) => {
					if ((ehUnidadeCompra && !item.UnidadeCompra) || this.desabilitarInputUnidadeCompra(item)) return;

					const unidade = ehUnidadeCompra ? item.UnidadeCompra : item.UnidadeVenda;
					this.alterarUnidadeItemNotaAssociada(indiceNota, indiceItem, unidade);
				});
			},

			verificarQuantidadeProduto(quantidade, item) {
				this.$nextTick(() => {
					if (quantidade < 0) quantidade = 0;
					if (quantidade > item.QuantidadeLimite) quantidade = item.QuantidadeLimite;

					item.Quantidade = quantidade;
				});
			},
		},
	};
</script>

<style scoped>
	.mensagem-nota-centralizada {
		height: calc(100vh - 200px);
	}

	.tr-group-by {
		line-height: 90% !important;
		background-color: #dee2e6 !important;
	}

	.input-quantidade {
		width: 13rem;
	}

	.col-produto-pendente {
		width: calc(77% - 13rem);
	}

	.col-produto-associado {
		width: calc(55% - 23rem);
	}

	.col-valor-total {
		width: 10rem;
	}

	.pb-65 {
		padding-bottom: 0.65rem !important;
	}

	.height-nota {
		height: calc(100vh - 245px);
	}
</style>
